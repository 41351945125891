.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 500px;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-content input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 90%;
}

.popup-content button {
  padding: 10px;
  border: none;
  background: #feb47b;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background: #feb47b;
}
