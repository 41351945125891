.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  
  min-height: 100vh;
  
  background: linear-gradient(135deg, #FF9A9E, #FAD0C4, #FBC2EB, #A1C4FD);
  margin-bottom: 0%;
  
}


/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(135deg, #FF9A9E, #FAD0C4, #FBC2EB, #A1C4FD);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: red;
}

.close-button:hover {
  color: darkred;
}
 
h1 {
  margin-bottom: 5px;
  color: #333;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.upload-section {
  text-align: center;
  
}

.upload-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.target-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  max-height: 417px;
  max-width: 360px;
  overflow-y: auto;
  
}
@media (display-mode: standalone) {
  .target-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    max-height: 540px;
    max-width: 360px;
    overflow-y: auto;
    
  }
  }



.target-image {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 12px;
  overflow: hidden;
  transition: border-color 0.3s, transform 0.3s;
  max-width: 330px; /* Increased size */
  max-height: 300px; /* Adjusted for Instagram-like feed */
}

.target-image.selected {
  border-color: #ff4400; /* Highlight color for selected image */
  transform: scale(1.05); /* Slight zoom effect for selected image */
}

.target-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  
}

@media (max-width: 600px) {
  .target-image {
    max-width: 380px;
    max-height: 350px;
  }
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.result-container img {
  max-width: 90%;
  max-height: 80vh; /* Adjust as needed to fit your design */
  border: 2px; /* Border color to highlight the result */
  border-radius: 8px; /* Rounded corners for the image */
  margin-bottom: 10px; /* Space between the image and button */
}

/* Button styles */
button {
  background-color: #F4418B; /* Pink shade from Instagram gradient */
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  
}

button:hover {
  background-color: #fca35b; /* Button hover color */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

button:focus {
  outline: none;
}

button:active {
  transform: scale(0.98);
}

.dropdown-button {
  background-color: #fff0db;
  color: #0056b3;
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.dropdown-button:hover {
  background-color: #f8e6d3;
  transform: scale(1.05);
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-button:active {
  transform: scale(0.98);
}

